import React from 'react';
import './App.css';
import { MenuNav} from "./Views/Header";
import {Router} from "./Routing/Router";
import {Footer} from "./Views/Footer";


function App() {
  return (
    <div>
        <MenuNav/>
        <div className="App">
            <Router/>
        </div>
        <Footer/>
    </div>
  );
}

export default App;
