import {Button} from "react-bootstrap";

export const AbautMe = () => {
    return <>
        <header className="abaut-me">
            <div  className="welcome"> zdjęcie w tle</div>
            <div className="content">
                <p>witaj!</p>
                <p>na mojej stonie wizytówkowej</p>
                <br/>
                <h1>Małgorzata Jurczak</h1>
                <br/>
                <h4>Freelancer WordPress, Java Script Developer <br/>z doświadczeniem w różnych
                    dziedzinach informatyki.</h4>
                <br/>
                <Button size="lg" href="portfolio" variant="outline-warning">Zobacz moje portfolio</Button>
            </div>
        </header>
    </>
}
