import {BrowserRouter, Route, Routes} from "react-router-dom";
import {AbautMe} from "../Views/AbautMe";
import {Portfolio} from "../Views/Portfolio";
import {Contact} from "../Views/Contact";
import {FreeTime} from "../Views/FreeTime";
import {Lost} from "../Views/Lost";
import {Education} from "../Views/Education";
import {Expiriance} from "../Views/Expiriance";
import {Tech} from "../Views/Tech";

function HomeView() {
    return null;
}

export const Router = () => {
    return <div>
        <BrowserRouter>
        <Routes>
            <Route
                path="/"
                element={<AbautMe/>}
            ></Route>
            <Route
                path="/home"
                element={<AbautMe/>}
            ></Route>
            <Route
                path="/portfolio"
                element={<Portfolio/>}
            ></Route>
            <Route
                path="/contact"
                element={<Contact/>}
            ></Route>
            <Route
                path="/freetime"
                element={<FreeTime/>}
            ></Route>
            <Route
                path="/education"
                element={<Education/>}
            ></Route>
            <Route
                path="/experience"
                element={<Expiriance/>}
            ></Route>
            <Route
                path="/technology"
                element={<Tech/>}
            ></Route>
            <Route
                path="/*"
                element={<Lost/>}
            ></Route>
        </Routes>
        </BrowserRouter>

    </div>
}
