import {Col, Container, ListGroup, Row, Tab, Tabs} from "react-bootstrap";
import projekt1 from "../images/projektemilia.gif";
import projekt2 from "../images/projektemilia2.gif";
import projekt3 from "../images/4pages.jpg";
import projekt4 from "../images/4pages2.jpg";
import projekt5 from "../images/parafia.jpg";
import projekt6 from "../images/parafia2.jpg";
import projekt7 from "../images/lazur-790x400.jpg";
import projekt8 from "../images/hh.jpg";
import projekt9 from "../images/hh2.png";
import projekt10 from "../images/arch.gif";
import projekt11 from "../images/arch2.jpg";

export const Portfolio = () =>{

    return (
        <Container className="Portfolio">
            <h1> Wybrane projekty</h1>

            <Row className="mb-3 RowH">
                <Col>
                    <Tabs
                        defaultActiveKey="project"
                        id="fill-tab-example"
                        className="mb-3"
                        fill
                    >
                        <Tab  eventKey="project" title="Projekt w zespole Scrum (w REACT I NESTJS)">
                            <div className="web">
                                <img src={projekt8} alt=""/>
                                <img src={projekt9} alt=""/>
                            </div>
                        </Tab>
                        <Tab eventKey="profile" title="Opis projektu">
                            <div>
                                <p>"This platform, made exclusively for MegaK JS Course provides an easy way for HR departments of companies, including headhunters, to connect with people seeking employment in the IT industry.

                                    MegaK students can use it to showcase their skills in a standardized manner. HR personnel can effortlessly find suitable job candidates, conduct interviews, and offer cooperation. The platform is not intended to compete directly with job portals. Instead, its goal is to complement the market with a focus on MegaK students."</p>
                                <p> Mój wkład:</p>
                                <ListGroup>
                                    <ListGroup.Item> Backend: Stworzenie relacyjnej bazy danych, współpraca przy łączeniu z front, zmiany w sposobach pobierania studentów i inne. </ListGroup.Item>
                                    <ListGroup.Item> Frontend: Stworzenie komponentu wyświetlającego listę strudentów i inne działania we współpracy z osobami z zespołu. Praca nad cookies, authentication,</ListGroup.Item>
                                </ListGroup>
                            </div>
                        </Tab>
                        <Tab eventKey="contact" title="Technologie i linki do kodu">
                            <ListGroup>

                                <ListGroup.Item> Frontend ( React )
                                    <a className="urls__list-item-link"
                                       href="https://github.com/HessianPL/MegaK_HeadHunter_FE"
                                       target="_blank" rel="noreferrer">
                                        [ Cały kod projektu]
                                    </a>
                                    <a className="urls__list-item-link"
                                       href="https://github.com/HessianPL/MegaK_HeadHunter_FE/commits?author=MalgorzataJu"
                                       target="_blank" rel="noreferrer">
                                        [Tylko mój kod w projekcie]
                                    </a>
                                </ListGroup.Item>
                                <ListGroup.Item>  Beckend ( Nest.js )
                                    <a className="urls__list-item-link" href="https://github.com/perlus3/HeadHunterBE"
                                       target="_blank" rel="noreferrer">
                                        [Cały kod projektu] </a>
                                    <a className="urls__list-item-link"
                                       href="https://github.com/perlus3/HeadHunterBE/commits?author=MalgorzataJu"
                                       target="_blank" rel="noreferrer">
                                        [Tylko mój kod w projekcie]</a>

                                </ListGroup.Item>
                            </ListGroup>
                        </Tab>
                    </Tabs>
                </Col>
            </Row>
            <Row className="mb-3 RowH">
                <Col>
                    <Tabs
                        defaultActiveKey="project"
                        id="fill-tab-example"
                        className="mb-3"
                        fill
                    >
                        <Tab  eventKey="project" title="Projekt Rejest">
                            <div className="web">
                                <img src={projekt10} alt=""/>
                                <img src={projekt11} alt=""/>
                            </div>
                        </Tab>
                        <Tab eventKey="profile" title="Opis projektu">
                            <div>
                                <ListGroup>
                                    <ListGroup.Item>Dostęp do aplikacji jest wyłącznie dla zarejestrowanych pracowników. Aplikacja ma za zadanie ułatwić rejestrację czasu pracy pracwonikom biura projektowego.
                                        Godziny pracy mają związek z odpowiednim projektem ale też są kategoryzowane i przypisane do odpowiedniego pracownika.</ListGroup.Item>

                                    <ListGroup.Item>rejeatracja czasu pracy,</ListGroup.Item>
                                    <ListGroup.Item>    zarządzanie pracownikami,</ListGroup.Item>
                                    <ListGroup.Item>     zarządanie projektami,</ListGroup.Item>
                                    <ListGroup.Item>     dodawanie, usuwanie i edycję pracowników</ListGroup.Item>
                                    <ListGroup.Item>     dodawanie, usuwanie i edycję projektów</ListGroup.Item>
                                    <ListGroup.Item>   dodawanie i usuwanie godzin pracy</ListGroup.Item>
                                    <ListGroup.Item>   CELE do zrealizowania w dalszym rozwoju:</ListGroup.Item>
                                    <ListGroup.Item>   przydzielanie zadań i pilnowanie założonych godzin pracy,</ListGroup.Item>

                                </ListGroup>
                            </div>
                        </Tab>
                        <Tab eventKey="contact" title="Technologie i linki do kodu">
                            <ListGroup>
                                <ListGroup.Item>  <h2>Rejestrator czasu pracy</h2></ListGroup.Item>
                                <ListGroup.Item> <p>Patforma służąca do rejestracji czasu pracy dla zdalnych pracowników biura
                                    projektowego. Wykonany w całości samodzielnie. W trakcie testowania przez
                                    użytkownika.</p></ListGroup.Item>
                                <ListGroup.Item> <p>Frontend ( React ) </p></ListGroup.Item>
                                <ListGroup.Item> <a className="urls__list-item-link"
                                                    href="https://github.com/MalgorzataJu/CRPArchitekt_frontend" target="_blank"
                                                    rel="noreferrer">
                                    [Kod projektu na githubie]</a></ListGroup.Item>
                                <ListGroup.Item>  <p> Beckend ( Nest.js )</p></ListGroup.Item>
                                <ListGroup.Item> <a className="urls__list-item-link" href="https://github.com/MalgorzataJu/CRPArchitekt"
                                                    target="_blank" rel="noreferrer">
                                    [Kod projektu na githubie]</a></ListGroup.Item>

                            </ListGroup>
                        </Tab>
                    </Tabs>
                </Col>
            </Row>
            <Row className="mb-3 RowH">
                <Col>
                        <Tabs
                            defaultActiveKey="project"
                            id="fill-tab-example"
                            className="mb-3"
                            fill
                        >
                            <Tab  eventKey="project" title="strona www.emiliabogdanowicz.pl">
                                <div className="web">
                                    <img src={projekt1} alt=""/>
                                    <img src={projekt2} alt=""/>
                                </div>
                            </Tab>
                            <Tab eventKey="profile" title="Opis projektu">
                                <div>
                                    <h3>Responsywna strona Interentowa pracowni architektonicznej.</h3>
                                    <p>  Działania:</p>
                                    <ListGroup>
                                        <ListGroup.Item> dobranie sposobu prezentacji strony do wymagań klienta, </ListGroup.Item>
                                        <ListGroup.Item> galeria, zmiana styli ukadu itp</ListGroup.Item>
                                        <ListGroup.Item> integracja recenzji google</ListGroup.Item>
                                        <ListGroup.Item> formularz kontaktowy, </ListGroup.Item>
                                        <ListGroup.Item> mapa google, optymalizacja SEO,</ListGroup.Item>
                                        <ListGroup.Item> elementy witryny zgodnie z wymaganiami klienta</ListGroup.Item>
                                    </ListGroup>
                                </div>
                            </Tab>
                            <Tab eventKey="contact" title="Technologie">
                                <ListGroup>
                                    <ListGroup.Item> Wordpress </ListGroup.Item>
                                </ListGroup>
                            </Tab>
                        </Tabs>
                </Col>
            </Row>
            <Row className="mb-3 RowH">
                <Col>
                    <Tabs
                        defaultActiveKey="project"
                        id="fill-tab-example"
                        className="mb-3"
                        fill
                    >
                        <Tab  eventKey="project" title="strona www.4pages.pl">
                            <div className="web">
                                <img src={projekt3} alt=""/>
                                <img src={projekt4} alt=""/>
                            </div>
                        </Tab>
                        <Tab eventKey="profile" title="Opis projektu">
                            <div>
                                <h3>Responsywna strona Interentowa </h3>
                                <p>  Działania:</p>
                                <ListGroup>
                                    <ListGroup.Item> dobranie sposobu prezentacji strony do wymagań klienta, </ListGroup.Item>
                                </ListGroup>

                            </div>
                        </Tab>
                        <Tab eventKey="contact" title="Technologie">
                            <ListGroup>
                                <ListGroup.Item> Wordpress </ListGroup.Item>
                            </ListGroup>
                        </Tab>
                    </Tabs>

                </Col>
            </Row>
            <Row className="mb-3 RowH">
                <Col>
                    <Tabs
                        defaultActiveKey="project"
                        id="fill-tab-example"
                        className="mb-3"
                        fill
                    >
                        <Tab  eventKey="project" title="strona www.parafia.slavo.pl">
                            <div className="web">
                                <img src={projekt5} alt=""/>
                                <img src={projekt6} alt=""/>
                            </div>
                        </Tab>
                        <Tab eventKey="profile" title="Opis projektu">
                            <div>
                                <h3>Responsywna strona Responsywna strona Interentowa dla parafii kościoła Rzymskokatolickiego.</h3>
                                <p>  Działania:</p>
                                <ListGroup>
                                    <ListGroup.Item> dobranie sposobu prezentacji strony do wymagań klienta, </ListGroup.Item>
                                    <ListGroup.Item> Kalendarz Google Api</ListGroup.Item>
                                    <ListGroup.Item> Umieszczenie wyników skryptu z zewnętrznego Api "Czytania na dziś"</ListGroup.Item>
                                    <ListGroup.Item> Przygotowanie zdjęć, stworzenie logo</ListGroup.Item>
                                    <ListGroup.Item> mapa google, optymalizacja SEO,</ListGroup.Item>
                                </ListGroup>
                            </div>
                        </Tab>
                        <Tab eventKey="contact" title="Technologie">
                            <ListGroup>
                                <ListGroup.Item> Wordpress </ListGroup.Item>
                            </ListGroup>
                        </Tab>
                    </Tabs>
                </Col>
            </Row>
            <Row className="mb-3 RowH">
                 <Col>
                     <Tabs
                         defaultActiveKey="project"
                         id="fill-tab-example"
                         className="mb-3"
                         fill
                     >
                         <Tab  eventKey="project" title="strona www.meble-lazur.pl">
                             <div className="web">
                                 <img src={projekt7} alt=""/>
                             </div>
                         </Tab>
                         <Tab eventKey="profile" title="Opis projektu">
                             <div>
                                 <h3>Strona dla osoby prowadzącej działalność gospodarczą.</h3>
                                 <p>  Działania:</p>
                                 <ListGroup>
                                     <ListGroup.Item> dobranie sposobu prezentacji strony do wymagań klienta, </ListGroup.Item>
                                            <ListGroup.Item> elementy witryny zgodnie z wymaganiami klienta</ListGroup.Item>
                                 </ListGroup>
                             </div>
                         </Tab>
                         <Tab eventKey="contact" title="Technologie">
                             <ListGroup>
                                 <ListGroup.Item> Wordpress </ListGroup.Item>
                             </ListGroup>
                         </Tab>
                     </Tabs>
                 </Col>
            </Row>

        </Container>
    )
}
