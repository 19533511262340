export const FreeTime = () => {
    return <>
        <section className="slogan">
            <div className="bg">
                <h1>Moje motto</h1>
                <p className="text">"Życie jest jak pudełko czekoladek, nigdy nie wiesz co ci się trafi..." </p>
                <p className="author">Forrest Gump</p>
            </div>
        </section>
        <section className="hobby clearfix">
            <h3>W wolnym czasie uwielbiam czytać szczególnie o Neurodydaktyce i biografie.</h3>
            <h4>Ale też:</h4>
            <div className="item">
                <p>Spędzać czas z bliskimi</p>
            </div>
            <div className="item">
                <p>Patrzeć w niebo</p>
            </div>
            <div className="item">
                <p>Rozwijać się, czytać, poznawać, doświadczać</p>
            </div>
            <div className="item">
                <p>Jeść, spać...</p>
            </div>
        </section>
    </>
}
