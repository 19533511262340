import {Card, Image, Placeholder} from "react-bootstrap";
import contact1 from '../images/contact1.png';
import contact2 from '../images/contact2.png';
import contact3 from '../images/contact3.png';
import contact4 from '../images/contact4.png';
import contact5 from '../images/contact5.png';

export const Contact = () =>{

    return <>
        <section className="contact">
            <h1>Zapraszam do kontaktu:</h1>
            <div>
                <Card>
                    <Card.Body >
                        <Placeholder as={Card.Title} animation="glow">
                            <Placeholder xs={12} />
                        </Placeholder>
                        <Card.Title>Oto możliwości:</Card.Title>
                        <Card.Text>
                            <div >
                                <img src={contact1} alt="kontakt"/><span>malgorzata@4pages.pl</span>
                            </div>
                            <div>
                                <img src={contact2} alt="kontakt"/><span>505 339 229</span>
                            </div>
                            <div>
                                <a href="https://www.linkedin.com/in/ma%C5%82gorzata-j-6ab815162/"><img src={contact4} alt="kontakt"/></a>
                            </div>
                            <div>
                                <a href="https://github.com/MalgorzataJu"><img src={contact3} alt="kontakt"/></a>
                            </div>
                        </Card.Text>

                        <Placeholder as={Card.Title} animation="glow">
                            <Placeholder xs={12} />
                        </Placeholder>
                    </Card.Body>
                </Card>


            </div>
        </section>
    </>
}
