import {Accordion, ListGroup} from "react-bootstrap";

export const Tech = () => {
    return <>
        <div className="education">
            <h1>Umiejętności</h1>
            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Technologie</Accordion.Header>
                    <Accordion.Body>
                        <ListGroup >
                            <ListGroup.Item> JavaScript (w tym jQuery) </ListGroup.Item>
                            <ListGroup.Item> React </ListGroup.Item>
                            <ListGroup.Item> TypeScript </ListGroup.Item>
                            <ListGroup.Item> Node.js, Express.js, NestJS</ListGroup.Item>
                            <ListGroup.Item> HTML5, CSS, MySQL</ListGroup.Item>
                            <ListGroup.Item> Git, Github</ListGroup.Item>
                            <ListGroup.Item> WordPress</ListGroup.Item>
                            <ListGroup.Item> Photoshop</ListGroup.Item>
                            <ListGroup.Item> VBA, Microsoft Office</ListGroup.Item>
                        </ListGroup>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>nie techniczne</Accordion.Header>
                    <Accordion.Body>
                        <ListGroup >
                            <ListGroup.Item> Chętnie się uczę i nie boję sie wyzwań</ListGroup.Item>
                            <ListGroup.Item> Jestem odpowiedzialna</ListGroup.Item>
                            <ListGroup.Item> Do zadań podchodzę analitycznie</ListGroup.Item>
                            <ListGroup.Item> Lubię poznawać ludzi, łatwo nawiązuję kontakt</ListGroup.Item>
                            <ListGroup.Item> Lubię pracę zespołową</ListGroup.Item>
                            <ListGroup.Item> Jestem skupiona na rozwiązaniach</ListGroup.Item>
                            <ListGroup.Item> Lubię dzielić się wiedzą</ListGroup.Item>
                            <ListGroup.Item> Pracuje z zaangażowaniem</ListGroup.Item>
                        </ListGroup>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header>Języki</Accordion.Header>
                    <Accordion.Body>
                        <ListGroup >
                            <ListGroup.Item> Angielski: B2</ListGroup.Item>
                        </ListGroup>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    </>
}
