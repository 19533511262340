import {Accordion, ListGroup} from "react-bootstrap";

export const Expiriance = () => {
    return <>
        <div className="education">
            <h1>Doswiadczenie zawodowe</h1>
            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Header><h4>Freelancer  od 2019 </h4></Accordion.Header>
                    <Accordion.Body>
                        <ListGroup >
                            <ListGroup.Item>  Tworzenie stron internetowych opartych głównie na Wordpress, przykłady w moim portfolio. </ListGroup.Item>
                            <ListGroup.Item>  Nieustanna nauka takich technologii jak: JavaScript, TypeScript, Nest.js, Node.js, React, MySQL </ListGroup.Item>
                            <ListGroup.Item>  Platforma służąca do rejestracji czasu pracy oparta o Nestjs i React. Obecnie w fazie wdrażania u klienta. </ListGroup.Item>
                            <ListGroup.Item>  Jak również tworzenie usprawnień dla zleceniodawcy. Aplikacje usprawniające pracę w MS office, programy w VBA np wypełnianie dokumentów Worda damyni z Ecela.</ListGroup.Item>
                        </ListGroup>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header><h4>Współtwórca i współwłaściciel sklepu internetowego cze 2009 – sty 2014 </h4></Accordion.Header>
                    <Accordion.Body>
                        <ListGroup >
                            <ListGroup.Item> Współtworzenie, testowanie platformy sklepu, różnych wdrażanych rozwiązań. </ListGroup.Item>
                            <ListGroup.Item> Tworzenie zdjęć produktów, obróbka w Photoshop, dodawanie opisów w języku polskim ( produkty niszowe na polskim rynku) </ListGroup.Item>
                            <ListGroup.Item> Stworzenie kompletengo serwisu informacyjnego ( na Joomla) o produktach i ich zastosowaniu.</ListGroup.Item>
                        </ListGroup>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header><h4>Informatyk styczeń 2007 – pażdziernik 2011</h4></Accordion.Header>
                    <Accordion.Body>
                        <ListGroup >
                            <ListGroup.Item> Wojewódzki Urząd Pracy w Krakowie</ListGroup.Item>
                            <ListGroup.Item> ok 200 osób do obsługi dla działu Informatycznego</ListGroup.Item>
                            <ListGroup.Item> Wsparcie techniczne, pomoc i usprawnianie pracy pracowników urzędu. Prowadzenie wdrożeń oprogramowania, serwis komputerów pracowniczych, nadzór nad systemami, serwerami i wszelkim sprzętem elektronicznym.</ListGroup.Item>
                            <ListGroup.Item> Obsługa prgramów usprawniających pracę Urzędu.</ListGroup.Item>
                        </ListGroup>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                    <Accordion.Header><h4>Wykładowca / Trener przed 2007</h4> </Accordion.Header>
                    <Accordion.Body>
                        <ListGroup >
                            <ListGroup.Item> Centrum Kształcenia Kraków - Prowadzenie kursów i szkoleń w zakresie podstaw obsługi komputera
                                i programów z pakietu MS Office dla różnych grup wiekowych.</ListGroup.Item>
                            <ListGroup.Item> Zespół Szkół w Lublinie - Nauczyciel informatyki/technologii informacyjnej oraz wychowawca klasy.
                                Nadzór nad poprawnością działania komputerów, drukarek szkolnych. Zakupy nowych sprzętów. Prowadzenie strony internetowej.</ListGroup.Item>
                        </ListGroup>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    </>
}
