import {Accordion, ListGroup} from "react-bootstrap";

export const Education = () => {
    return <>
        <div className="education">
            <h1>Edukacja</h1>
            <ListGroup >
                <ListGroup.Item>
                    <h3>Matematyka o specjalności informatycznej - stacjonarne 5 letnie ( 2000 – 2005 )</h3>
                    <ListGroup >
                        <ListGroup.Item>  Katolicki Uniwersytet Lubelski Jana Pawła II </ListGroup.Item>
                        <ListGroup.Item>  Magister matematyki o specjalności informatycznej(Mgr)</ListGroup.Item>
                    </ListGroup>
                </ListGroup.Item>
                <ListGroup.Item>
                    <h3>Rozwój Zasobów Ludzkich ( 2007 – 2008 )</h3>
                    <ListGroup >
                        <ListGroup.Item>  Wyższa Szkoła Zarządzania w Warszawie - The Polish Open University</ListGroup.Item>
                        <ListGroup.Item>  Studia Podyplomowe</ListGroup.Item>
                    </ListGroup>
                </ListGroup.Item>
                <ListGroup.Item>
                    <h3>MegaK Full Stack Developer ( mar 2022 – maj 2023 )</h3>
                    <ListGroup >
                        <ListGroup.Item>  Full Stack JavaScript Developer</ListGroup.Item>
                        <ListGroup.Item>  Kurs internetowy z technologiami takimi jak:</ListGroup.Item>
                        <ListGroup.Item>  JavaScript, TypeScript, Node, Express, MSQL, MongoDB, React, NestFull Stack JavaScript Developer, JavaScript, TypeScript, Node, Express, MSQL, MongoDB, React, Nest
                        </ListGroup.Item>
                        <ListGroup.Item> Projekt zaliczeniowy, kod na github <a href="https://github.com/MalgorzataJu/projektArchitekt">[Projekt RCP Architekt]</a></ListGroup.Item>
                        <ListGroup.Item> Projekt końcowy, grupowy w Scrum ( miesiąc współpracy )
                            <p> Frontend ( React )</p>
                            <a className="urls__list-item-link"
                               href="https://github.com/HessianPL/MegaK_HeadHunter_FE"
                               target="_blank" rel="noreferrer">
                                [ Cały kod projektu]
                            </a>
                            <a className="urls__list-item-link"
                               href="https://github.com/HessianPL/MegaK_HeadHunter_FE/commits?author=MalgorzataJu"
                               target="_blank" rel="noreferrer">
                                [Tylko mój kod w projekcie]
                            </a>

                            <p>Beckend ( Nest.js )</p>
                            <a className="urls__list-item-link" href="https://github.com/perlus3/HeadHunterBE"
                               target="_blank" rel="noreferrer">
                                [Cały kod projektu] </a>
                            <a className="urls__list-item-link"
                               href="https://github.com/perlus3/HeadHunterBE/commits?author=MalgorzataJu"
                               target="_blank" rel="noreferrer">
                                [Tylko mój kod w projekcie]</a>

                        </ListGroup.Item>
                    </ListGroup>
                </ListGroup.Item>
                <ListGroup.Item>
                    <h3>Kursy na Udemy od 2018</h3>
                    <ListGroup >
                        <ListGroup.Item>Kompletny przewodnik JavaScript - edycja 2021 i quizy!</ListGroup.Item>
                        <ListGroup.Item>Programowanie w JavaScript </ListGroup.Item>
                        <ListGroup.Item>Basics of Scrum, Agile and Project Delivery</ListGroup.Item>
                        <ListGroup.Item>One Page WordPress Website</ListGroup.Item>
                        <ListGroup.Item> Angular - kompletny kurs od podstaw - edycja na rok 2021</ListGroup.Item>
                        <ListGroup.Item>PHP For WordPress Development </ListGroup.Item>
                        <ListGroup.Item>Front-end średniozaawansowany </ListGroup.Item>
                    </ListGroup>
                </ListGroup.Item>
            </ListGroup>
        </div>
    </>
}
